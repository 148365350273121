export const USER_REGISTER = 'RABADAN/USER/REGISTER';
export const USER_REGISTER_SUCCESS = 'RABADAN/USER/REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'RABADAN/USER/REGISTER_FAIL';

export const USER_LOGIN = 'RABADAN/USER/LOGIN';
export const USER_LOGIN_SUCCESS = 'RABADAN/USER/LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'RABADAN/USER/LOGIN_FAIL';

export const SET_USER = 'RABADAN/USER/SET_USER';

export const USER_LOGOUT = 'RABADAN/USER/LOGOUT';

export const SET_MESSAGE = 'RABADAN/MESSAGE/SET';
export const CLEAR_MESSAGE = 'RABADAN/MESSAGE/CLEAR';

export const FEEDBACK_CREATE = 'RABADAN/FEEDBACK/CREATE';
export const FEEDBACK_CREATE_SUCCESS = 'RABADAN/FEEDBACK/CREATE_SUCCESS';
export const FEEDBACK_CREATE_FAIL = 'RABADAN/FEEDBACK/CREATE_FAIL';

export const GET_PAGE = 'RABADAN/PAGE/SHOW';
export const GET_PAGE_SUCCESS = 'RABADAN/PAGE/SHOW_SUCCESS';
export const GET_PAGE_FAIL = 'RABADAN/PAGE/SHOW_FAIL';
export const SET_PAGE = 'RABADAN/PAGE/SET';
export const CHANGE_PAGE = 'RABADAN/PAGE/CHANGE_PAGE';

export const GET_PORTFOLIO_ITEMS = 'RABADAN/PORTFOLIO_ITEM/INDEX';
export const GET_PORTFOLIO_ITEMS_SUCCESS = 'RABADAN/PORTFOLIO_ITEM/INDEX_SUCCESS';
export const GET_PORTFOLIO_ITEMS_FAIL = 'RABADAN/PORTFOLIO_ITEM/INDEX_FAIL';

export const GET_BLOGS = 'RABADAN/BLOG/INDEX';
export const GET_BLOGS_SUCCESS = 'RABADAN/BLOG/INDEX_SUCCESS';
export const GET_BLOGS_FAIL = 'RABADAN/BLOG/INDEX_FAIL';
export const GET_BLOG = 'RABADAN/BLOG/SHOW';
export const GET_BLOG_SUCCESS = 'RABADAN/BLOG/SHOW_SUCCESS';
export const GET_BLOG_FAIL = 'RABADAN/BLOG/SHOW_FAIL';
export const SET_BLOG = 'RABADAN/BLOG/SET';
export const SET_BLOG_SUCCESS = 'RABADAN/BLOG/SET_SUCCESS';
export const SET_BLOG_FAIL = 'RABADAN/BLOG/SET_FAIL';

export const CHANGE_BLOG = 'RABADAN/BLOG/CHANGE_BLOG';

export const GET_CONFIGURATION = 'RABADAN/CONFIGURATION/INDEX';
export const GET_CONFIGURATION_SUCCESS = 'RABADAN/CONFIGURATION/INDEX_SUCCESS';
export const GET_CONFIGURATION_FAIL = 'RABADAN/CONFIGURATION/INDEX_FAIL';
export const SET_LANG = 'RABADAN/CONFIGURATION/SET_LANG';

export const CHANGE_CONFIGURATION = 'RABADAN/CONFIGURATION/CHANGE';
export const SET_CONFIGURATION_FAIL = 'RABADAN/CONFIGURATION/SET_FAIL';